import React, { useCallback, useEffect, useRef, useState } from 'react'

type CommandResult<T> = [
  // InvokeCommand => Invokes the command, call this in an event handler
  () => Promise<T | null>,
  // Result => The last result from the command invocation
  Box<T | null>,
  // Reset => Resets the command to its unset value
  () => void
]

/**
 * This method creates a Command, which is an asynchronous background operation
 * triggered by an Event Handler, that returns a value. It allows you to get the
 * current state of the operation (pending/complete/error), as well as trigger it
 * via an event handler.
 *
 * Similar to useAsyncCallbackDedup, Commands also prevent multiple in-flight requests
 * from happening concurrently
 *
 * @param block - the async Function to run in the background. The value
 *                returned from this method will be put into `current` once
 *                completed
 * @param deps - a DependencyList that will cause the Function to be rebuilt,
 *               a-la useCallback
 * @param runOnStart - if true, the Command will be automatically executed when
 *                     the Component mounts
 * @return {
 *  invokeCommand - a Function that you can call in event handlers, that will run
 *                  the code in `block`
 *  current - the result of the last invocation of the Command, wrapped in a Box
 *  reset - reset the saved value
 * }
 */
export function useCommand<T>(
  block: () => Promise<T>,
  deps: React.DependencyList,
  runOnStart = false
): CommandResult<T> {
  const mounted = useMounted()
  const [current, setCurrent] = useState<Box<T | null>>(resultBox(null))

  const reset = useCallback(() => {
    if (mounted.current) {
      setCurrent(resultBox(null))
    }
  }, [mounted])

  const invokeCommand = useAsyncCallbackDedup(async () => {
    try {
      if (mounted.current) setCurrent(pendingBox<T>())
      const ret = await block()
      if (mounted.current) setCurrent(resultBox(ret))

      return ret
    } catch (e) {
      if (mounted.current) {
        setCurrent(errorBox(e as Error))
        return null
      } else {
        throw e
      }
    }
  }, deps)

  usePromise(async () => {
    if (runOnStart) {
      await invokeCommand()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invokeCommand])

  return [invokeCommand, current, reset]
}

/**
 * A Hook that allows you to wrap an asynchronous function and get its current
 * value. Use this hook whenever you would otherwise use `useEffect` and any method
 * that returns a Promise
 *
 * @param block - the async Function to run in the background. The value
 *                returned from this method will be put into the returned Box
 * @param deps - a DependencyList that will cause the Function to be called again,
 *               a-la useEffect
 * @return a Box representing the current result of the Promise - use methods like
 *         isPending / asResult / asError to decide whether to render a
 *         pending/error state, or to render the result
 */
export function usePromise<T>(
  block: (isCancelled: React.RefObject<boolean>) => Promise<T>,
  deps?: React.DependencyList
): Box<T> {
  const [ret, setRet] = useState<Box<T>>(pendingBox<T>())

  useEffect(() => {
    let cancelled = { current: false }

    block(cancelled).then(
      (r) => {
        if (!cancelled.current) setRet({ result: r })
      },
      (e: Error) => {
        cancelled.current = true
        setRet({ error: e })
      }
    )

    return () => {
      cancelled.current = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return ret
}

/**
 * A React Hook that tells you whether a component has been mounted or not.
 * Use this Hook to avoid calling State methods (i.e. setFooBarBaz) after the
 * component has been unmounted, or to determine whether to cancel in-flight
 * requests
 *
 * @return true if the component is currently mounted, false otherwise
 */
export function useMounted() {
  const mounted = useRef<boolean>(true)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [mounted])

  return mounted
}

/**
 * This Hook allows you to explicitly trigger a re-render of your component, which
 * can be useful when you need to rebuild based on an event handler, but don't have
 * an associated State to change
 *
 * @export
 * @return {
 *  dep: a value that changes whenever rerender is called, pass this into any
 *       useEffect calls or other Hooks that use Dependency Lists to trigger an
 *       update.
 *  rerender: a Function that, when called, will trigger the component to re-render
 * }
 */
export function useExplicitRender() {
  const [n, setN] = useState(0)

  const rerender = useCallback(() => setN((x) => x + 1), [])
  return { dep: n, rerender }
}

/**
 * This method wraps an asynchronous event handler to make it a no-op while an
 * existing operation is in-flight. Use this method to prevent async event
 * handlers from being called multiple times
 *
 * @param block - the async Function to wrap
 * @param deps - a DependencyList that will cause the Function to be rebuilt,
 *               a-la useCallback
 * @return {*}  {(() => Promise<T | null>)}
 */
export function useAsyncCallbackDedup<T>(
  block: () => Promise<T>,
  deps: React.DependencyList
): () => Promise<T | null> {
  const cur = useRef<Promise<T>>()

  const cb = useCallback(async () => {
    if (cur.current) return null

    cur.current = block()
    return await promiseFinally(cur.current, () => (cur.current = undefined))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return cb
}

// A Box is kind of like a Promise's result that you can inspect ahead-of-time.
// It is either a result, an Error, or {} (meaning that it is pending).
export type Box<T, TError extends Error = Error> =
  | { result?: T | undefined }
  | { error: TError }

/**
 * Returns whether a Box is pending or not (i.e. has not completed yet)
 *
 * @param {Box<T>} the box, usually returned from useCommand or usePromise
 * @return true if the Promise has not yet completed
 */
export function isPending<T>(box: Box<T>) {
  return !('result' in box) && !('error' in box)
}

/**
 * Returns the Error associated with a Box, or null if it is pending/completed
 *
 * @param {Box<T>} The Box
 * @return An Error or null
 */
export function asError<T, TError extends Error = Error>(box: Box<T, TError>) {
  return 'error' in box ? box.error : null
}

/**
 * Returns the result associated with a Box, or null if it is pending/errored
 *
 * @param {Box<T>} The Box
 * @return The result of the box, or null
 */
export function asResult<T>(box: Box<T>) {
  return 'result' in box ? box.result : null
}

/**
 * Retrieves the value of a box, similar to what await would do for a Promise.
 * If the box has an Error, it throws that error, and if it is a Result, it
 * returns that result. If the box is pending, it returns null
 *
 * @param {Box<T>} The Box
 * @return The result of the box, or null if the box is pending
 */
export function unbox<T>(box: Box<T>) {
  const err = asError(box)
  if (err) throw err

  return asResult(box)
}

/**
 * Creates a new Box that is in the pending state
 *
 * @return {*}  {Box<T>}
 */
export function pendingBox<T>(): Box<T> {
  return {}
}

/**
 * Creates a new Box that is a completed value, similar to Promise.resolve
 *
 * @return {*}  {Box<T>}
 */
export function resultBox<T>(result: T): Box<T> {
  return { result }
}

/**
 * Creates a new Box that is a failed result, similar to Promise.reject
 *
 * @return {*}  {Box<T>}
 */
export function errorBox<T>(error: Error): Box<T> {
  return { error }
}

/**
 * Returns a Promise that completes after a certain amount of time has
 * elapsed
 *
 * @export
 * @param {number} ms - the delay in Milliseconds
 * @return A Promise<number> whose future value is the delay in milliseconds (i.e. ms)
 */
export function delay(ms: number) {
  return new Promise<number>((res) => setTimeout(() => res(ms), ms))
}

/**
 * This method allows you to explicitly signal that a Promise's result should be
 * ignored. Instead of writing 'void ' in front of it, use this method instead. This
 * method logs errors by default, but this can be disabled
 *
 * @param {Promise<T>} p - the Promise to ignore
 * @param {boolean} dontLogFailures - if set, Errors will not be logged to console
 */
export function unawaited<T>(p: Promise<T>, dontLogFailures = false) {
  p.then(
    (_) => {},
    (e) => {
      if (dontLogFailures) return
      console.error(e)
    }
  )
}

/**
 * This method allows you to execute code when a Promise completes, regardless of
 * whether it succeeds or not, similar to the `finally` block in `try/catch/finally`.
 * It does _not_ catch the Error if one is thrown
 *
 * @param p - the Promise to watch
 * @param block - the Function to execute on completion
 * @return A new Promise that is effectively the same value as p
 */
export function promiseFinally<T>(p: Promise<T>, block: () => unknown) {
  return p.then(
    (x) => {
      block()
      return Promise.resolve(x)
    },
    (e) => {
      block()
      return Promise.reject(e)
    }
  )
}

/**
 * Retry an asynchronous operation N times. If it continues to fail, the Error
 * is propagated upwards
 *
 * @param {() => Promise<T>} block - the code to try until it succeeds
 * @param {number} [retries=3] - the number of retries to attempt before giving up
 * @return The value returned by block.
 */
export async function retry<T>(block: () => Promise<T>, retries = 3) {
  let count = retries
  let err: any

  while (count > 0) {
    try {
      return await block()
    } catch (e) {
      err = e
    }

    await delay(Math.random() * 250)
    count--
  }

  throw err
}
