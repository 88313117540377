import React from 'react'
import { Box, ExtendedBoxProps } from '@chatterbug/aaron'

export type InputProps = Omit<Omit<ExtendedBoxProps, 'as'>, 'css'> & {
  hintText: string
  id: string
  errorText?: string
}

const StreamsInput: React.FC<InputProps> = ({
  sx,
  hintText,
  errorText,
  placeholder,
  id,
  ...props
}) => {
  const customStyle = sx ?? {}
  const labelColor = errorText ? 'red68' : 'gray46'
  const textColor = errorText ? 'red68' : 'black19'
  const borderColor = errorText ? 'red68' : 'gray80'
  const focusBorderColor = errorText ? 'red87' : 'gray80'

  return (
    <Box
      sx={{
        marginTop: 16,
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'start',
      }}
    >
      <Box
        as="fieldset"
        sx={{
          position: 'relative',
          marginTop: '1x',
          borderRadius: 32,
          borderWidth: 1,
          borderColor: borderColor,
          borderStyle: 'solid',
          ':focus-within, :active': {
            borderColor: focusBorderColor,
          },
        }}
      >
        <Box
          as="legend"
          bg="white100"
          borderRadius={32}
          color={labelColor}
          px="1x"
          sx={{
            top: -12,
            right: 24,
            position: 'absolute',
            userSelect: 'none',
            zIndex: 15,
            fontSize: 'xs',
          }}
        >
          {hintText}
        </Box>

        <Box
          as="input"
          bg="white100"
          borderColor={borderColor}
          color={textColor}
          px="1x"
          placeholder={placeholder}
          width="100%"
          height={24}
          sx={{
            position: 'relative',
            outline: 'none',
            border: '0px solid transparent',
            '::placeholder': {
              color: 'gray46',
            },
            ...customStyle,
          }}
          id={id}
          {...props}
        />
      </Box>
      {errorText && (
        <Box>
          <Box
            as="label"
            htmlFor={id}
            ml={-2} // compensate for border
            color={textColor}
            sx={{ fontSize: 'xs' }}
          >
            {errorText}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default StreamsInput
