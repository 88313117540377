import React, { useEffect, useMemo, useState } from 'react'
import { Box, Flex } from '@chatterbug/aaron'
import { useTranslate } from 'react-polyglot'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'

import StreamsInput from '../StreamsInput/StreamsInput'
import StreamsLogo from '../StreamsLogo/StreamsLogo'
import LocalizedLink from '../LocalizedLink/LocalizedLink'

import {
  canAccessStreams,
  DataError,
  login,
  saveToken,
  signup,
} from './signup-api'
import { asError, asResult, isPending, retry, useCommand } from 'src/lib/hooks'
import { SiteLocale } from 'src/types'
import { useLocale } from 'src/lib/I18nContext'
import {
  trackStreamsWebPromotion,
  trackStreamsGiftVoucherRedemption,
} from 'src/lib/tracking'

/*
// Use this when react-polyglot floods your console with error messages :eyeroll:
function useTranslate() {
  return (x: any) => x
}
*/

async function determineStreamsCheckoutUrl(
  planId: string,
  couponCode: string,
  giftVoucherCode: string,
  token: string,
  locale: SiteLocale
) {
  const {
    canAccessStreamsNow,
    streamsStripeCheckoutUrl,
    error,
  } = await canAccessStreams(token, planId, couponCode, locale)

  if (canAccessStreamsNow) {
    return `/${locale}/streams-subscription/existing-user`
  }

  if (giftVoucherCode) {
    return `/${locale}/streams-subscription/instructions?giftVoucherCode=${giftVoucherCode}`
  }

  if (error) {
    return `/${locale}/streams-subscription/pricing-plans`
  }

  return streamsStripeCheckoutUrl
}

function findPlanIdAndCouponCode() {
  const url = new URL(window.location.href)

  const ret = ['planId', 'couponCode', 'giftVoucherCode'].reduce(
    (acc: Record<string, string>, k) => {
      const v = url.searchParams.get(k)
      if (!v) return acc

      acc[k] = v
      return acc
    },
    {}
  )
  if (ret.giftVoucherCode) {
    ret['queryString'] = `giftVoucherCode=${encodeURIComponent(
      ret.giftVoucherCode ?? ''
    )}`
  } else {
    ret['queryString'] = `planId=${encodeURIComponent(
      ret.planId ?? ''
    )}&couponCode=${encodeURIComponent(ret.couponCode ?? '')}`
  }

  return ret
}

const StreamsSignupOrLoginPage: React.FC<{ isLogin: boolean }> = ({
  isLogin,
}) => {
  const [usernameDuringSignup, setUsernameDuringSignup] = useState('')
  const [password, setPassword] = useState('')
  const [emailOrUsername, setEmailOrUsername] = useState('')
  const t = useTranslate()
  const locale = useLocale()

  const buttonDisabled = isLogin
    ? !emailOrUsername || !password
    : !usernameDuringSignup || !password || !emailOrUsername

  const isSSR = typeof window === 'undefined'

  const { planId, couponCode, queryString, giftVoucherCode } = useMemo(() => {
    if (isSSR)
      return {
        planId: '',
        couponCode: '',
        queryString: '',
        giftVoucherCode: '',
      }
    return findPlanIdAndCouponCode()
  }, [isSSR])

  useEffect(() => {
    if (isSSR) return

    // When the user has a giftVoucher they should be allowed to access
    // the page
    if (giftVoucherCode) return

    if (!planId) {
      // If someone visits the signup page and they didn't come from some kind of
      // offer, bounce them to the main Streams home page
      if (!/localhost/.test(window.location.host)) {
        window.location.href = '/streams'
      } else {
        console.error(`No Plan ID!`)
      }
    }
  }, [planId, couponCode, isSSR, giftVoucherCode])

  const [
    handleSignupOrLogin,
    redirectLocation,
    resetCommand,
  ] = useCommand(async () => {
    if (isLogin) {
      if (!emailOrUsername || !password) return null
    } else {
      if (!usernameDuringSignup || !password || !emailOrUsername) return null
    }

    let token = ''

    if (!isLogin) {
      let alreadyHasAccount = false

      try {
        // If users accidentally try to use our signup form as a login form,
        // just pretend they did the right thing anyways
        token = await retry(() => login(usernameDuringSignup, password), 2)
        alreadyHasAccount = true
        console.log('User had an account already!')
      } catch {
        console.log("User doesn't exist, creating...")
      }

      if (!alreadyHasAccount) {
        token = await retry(() =>
          signup(usernameDuringSignup, emailOrUsername, password)
        )
      }
    } else {
      token = await retry(() => login(emailOrUsername, password))
    }

    console.log('Attempting to determine if account is eligible')

    // Once we come back from Stripe, we need to make an authenticated call
    // on the download page to associate our Stripe subscription with the
    // account
    saveToken(token)

    if (giftVoucherCode) {
      trackStreamsGiftVoucherRedemption(
        isLogin ? 'login' : 'signup',
        giftVoucherCode
      )
    } else {
      trackStreamsWebPromotion(isLogin ? 'login' : 'signup', planId)
    }

    return await retry(() =>
      determineStreamsCheckoutUrl(
        planId,
        couponCode,
        giftVoucherCode,
        token,
        locale
      )
    )
  }, [usernameDuringSignup, password, emailOrUsername, isLogin])

  useEffect(resetCommand, [
    usernameDuringSignup,
    password,
    emailOrUsername,
    resetCommand,
  ])

  const location = asResult(redirectLocation)

  if (location) {
    window.location.href = location
  }

  const error = asError<string | null, DataError>(redirectLocation)
  // NB: signup and auth return inconsistent JSON; show the error under the continue button
  // if there is an error which is not a validation one (e.g. server is dead)
  const showContinueButtonErrorMessage =
    error && !error?.data?.error && !error?.data?.error_message

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      pt={80}
      height="100vh"
    >
      <Flex
        flexDirection="column"
        width={{ _: '100%', tablet: 340 }}
        justifyContent="space-between"
      >
        {/* logo and title */}
        <Box>
          <StreamsLogo height={57} iconOnly />
          <Box as="h3" variant="text.h3" pt="3x" pb={72} color="black0">
            {isLogin ? t('Welcome back') : t('Claim your offer')}
          </Box>
        </Box>
        {/* white card w/ subtitle, form, button */}
        <Box
          bg="white100"
          px="2x"
          py="4x"
          borderRadius={16}
          boxShadow="raised"
          mb="3x"
        >
          <Box
            as="p"
            variant="text.buttonTitle"
            pb="3x"
            color="black0"
            fontSize="xxs"
          >
            {isLogin ? t('Log in to your account') : t('Create your account')}
          </Box>
          {/* form inputs */}
          {!isLogin && (
            <Box mb="4x">
              <StreamsInput
                id="1"
                hintText={t('Username')}
                errorText={error?.data?.errors?.login?.[0]}
                placeholder={t('eg. myusername')}
                value={usernameDuringSignup}
                autoComplete="username"
                onChange={(e: any) => setUsernameDuringSignup(e.target.value)}
              />
            </Box>
          )}
          <Box mb="4x">
            <StreamsInput
              id={isLogin ? '1' : '2'}
              hintText={isLogin ? t('Username') : t('Email Address')}
              errorText={error?.data?.errors?.email?.[0]}
              placeholder={
                isLogin ? t('Username or email') : t('eg. mail@example.com')
              }
              autoComplete="username"
              value={emailOrUsername}
              onChange={(e: any) => setEmailOrUsername(e.target.value)}
            />
          </Box>
          <StreamsInput
            id={isLogin ? '2' : '3'}
            hintText={t('Password')}
            errorText={error?.data?.errors?.password?.[0]}
            type="password"
            value={password}
            autoComplete={isLogin ? 'current-password' : 'new-password'}
            onChange={(e: any) => setPassword(e.target.value)}
            placeholder={
              isLogin ? '••••••••' : t('Must have at least 8 characters')
            }
          />
          {/* button & cta link */}
          <Box width={{ _: '100%', tablet: 'auto' }} mt={56}>
            <Button
              label={t('Continue')}
              href={null}
              disabled={
                buttonDisabled ||
                isPending(redirectLocation) ||
                // don't disable button if it a server-side (not validation) error, so that the user can re-trigger
                // the query without reloading the page. in case of a validation error button becomes enabled the moment user starts retyping
                (!!asError(redirectLocation) && !showContinueButtonErrorMessage)
              }
              onClick={handleSignupOrLogin}
              variant={BUTTON_VARIANTS.secondary}
              width="100%"
            />
            <Flex mt="3x" justifyContent="center">
              <Box
                as="p"
                variant="text.buttonTitle"
                fontSize="xxs"
                color="black0"
                mr="1x"
              >
                {isLogin
                  ? t("Don't have an account?")
                  : t('Already have an account?')}
              </Box>
              {/* cta link */}
              <LocalizedLink
                to={
                  isLogin
                    ? `/streams-subscription/signup?${queryString}`
                    : `/streams-subscription/login?${queryString}`
                }
              >
                <Box
                  variant="text.buttonTitle"
                  fontSize="xxs"
                  color="black0"
                  sx={{ textDecoration: 'underline' }}
                >
                  {isLogin ? t('Sign up') : t('Log in')}
                </Box>
              </LocalizedLink>
            </Flex>
            {showContinueButtonErrorMessage && (
              <Box
                as="p"
                variant="h3"
                marginTop="2x"
                paddingLeft="1x"
                color="red68"
                sx={{ fontSize: 'xs', textAlign: 'start' }}
              >
                {t(
                  'Something went wrong, please try again or contact support.'
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default StreamsSignupOrLoginPage
